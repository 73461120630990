import { useCallback, useEffect, useState } from "react";
import { isBrowser, isLighthouse } from "../browser-utils";

export type CookieConsent =
	| "strict"
	| "performance"
	| "targeting"
	| "functionality"
	| "unclassified";

const bypassConsent = (): boolean =>
	isLighthouse() || process.env.GATSBY_IS_PREVIEW === "true";

/**
 * Checks if a specific cookie type has consent to be used.
 * This function is used by Google Tag Manager to check if a specific cookie type is valid which will allow/disallow tags.
 *
 * @param type CookieTypeKey
 * @returns boolean
 */
export const hasCookieConsent = (consentCategory: CookieConsent): boolean => {
	if (consentCategory === "strict") return true;
	if (bypassConsent()) return true;

	if (!isBrowser()) return false;

	const currentState = window.CookieScript?.instance.currentState();
	return currentState?.categories.includes(consentCategory) ?? false;
};

export const useHasCookieConsent = (
	consentCategory: CookieConsent,
): boolean => {
	const [hasConsent, setHasConsent] = useState(
		hasCookieConsent(consentCategory),
	);

	const consentUpdater = useCallback(() => {
		setHasConsent(hasCookieConsent(consentCategory));
	}, [consentCategory]);

	useEffect(() => {
		if (!isBrowser()) return;

		window.addEventListener("CookieScriptLoaded", consentUpdater);
		window.addEventListener("CookieScriptAccept", consentUpdater);
		window.addEventListener("CookieScriptReject", consentUpdater);

		return () => {
			window.removeEventListener("CookieScriptLoaded", consentUpdater);
			window.removeEventListener("CookieScriptAccept", consentUpdater);
			window.removeEventListener("CookieScriptReject", consentUpdater);
		};
	}, [consentUpdater]);

	return hasConsent;
};
