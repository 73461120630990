import * as Sentry from "@sentry/gatsby";
import { isBrowser, isLighthouse } from "./src/utils/browser-utils";

const dsn = process.env.GATSBY_SENTRY_REDESIGN_DSN;

const ignoreErrors = [
	"Network request failed", // Probably just network issues on client
	"pintrk is not defined", // Related to pintrest
	"ChunkLoadError", // This seems to correlate to gatsby builds, when a new build is released we see these errors since there are new chunks but some requests might linger for old chunks.
	// https://github.com/getsentry/sentry-javascript/issues/6295#issuecomment-1352958925
	"https://reactjs.org/docs/error-decoder.html?invariant=422", // There was an error while hydrating this Suspense boundary. Switched to client rendering.
	"https://reactjs.org/docs/error-decoder.html?invariant=423", // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
	"https://reactjs.org/docs/error-decoder.html?invariant=425", // Text content does not match server-rendered HTML...
	"This Suspense boundary received an update before it finished hydrating", // This is a known issue with React 18

	// From https://gist.github.com/impressiver/5092952
	// Random plugins/extensions
	"top.GLOBALS",
	// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
	"originalCreateNotification",
	"canvas.contentDocument",
	"MyApp_RemoveAllHighlights",
	"http://tt.epicplay.com",
	"Can't find variable: ZiteReader",
	"jigsaw is not defined",
	"ComboSearch is not defined",
	"http://loading.retry.widdit.com/",
	"atomicFindClose",
	// Facebook borked
	"fb_xd_fragment",
	// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
	// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
	"bmi_SafeAddOnload",
	"EBCallBackMessageReceived",
	// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
	"conduitPage",
	// Generic error code from errors outside the security sandbox
	// You can delete this if using raven.js > 1.0, which ignores these automatically.
	"Script error.",
	// Avast extension error
	"_avast_submit",
	"Failed to fetch",
	"webkit-masked-url",
	"Minified React error #421",
	// See https://stackoverflow.com/questions/65732703/resizeobserver-loop-limit-exceeded-stop-exception
	"ResizeObserver loop limit exceeded",
];

export const shouldUseSentry = () =>
	process.env.GATSBY_FORCE_SENTRY ||
	(process.env.GATSBY_NETLIFY === "true" &&
		process.env.GATSBY_IS_PREVIEW !== "true" &&
		(process.env.GATSBY_BRANCH === "main" ||
			process.env.GATSBY_BRANCH === "staging"));

if (dsn && shouldUseSentry() && isBrowser()) {
	const sampleRate = Math.min(
		Number.parseFloat(process.env.GATSBY_SENTRY_REDESIGN_SAMPLE_RATE || "0"),
		1,
	);
	const replaysSessionSampleRate = Math.min(
		Number.parseFloat(
			process.env.GATSBY_SENTRY_REPLAY_SESSION_SAMPLE_RATE || "0",
		),
		1,
	);
	const replaysOnErrorSampleRate = Math.min(
		Number.parseFloat(
			process.env.GATSBY_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || "0",
		),
		1,
	);
	const tracesSampleRate = Math.min(
		Number.parseFloat(
			process.env.GATSBY_SENTRY_REDESIGN_TRACES_SAMPLE_RATE || "0",
		),
		1,
	);
	const debug = process.env.GATSBY_SENTRY_REDESIGN_DEBUG === "1";

	// Environment based on the branch name.
	let environment: string;

	// We use the branch name to determine the environment.
	// This is because we have a single Sentry project for all environments.
	// Right now only main and staging are used, but the logic supports dev and pr as well.
	switch (process.env.GATSBY_BRANCH) {
		case "main": {
			environment = "production";
			break;
		}
		case "staging": {
			environment = "staging";
			break;
		}
		case undefined: {
			environment = "development";
			break;
		}
		default: {
			environment = "pull_request";
		}
	}

	Sentry.init({
		dsn,
		sampleRate,
		environment,
		debug,
		replaysSessionSampleRate,
		replaysOnErrorSampleRate,
		tracesSampleRate,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
				maskAllInputs: true,
				mask: [".sentry-mask", "[data-sentry-mask]"],
			}),
		],
		beforeSend: (event) => {
			// Do not send events from Lighthouse.
			if (isLighthouse()) {
				console.info("Lighthouse detected, not sending event to Sentry.");
				return null;
			}

			// Do not send ChunkLoadError events.
			if (
				event.exception?.values?.some(
					(value) => value.type === "ChunkLoadError",
				)
			) {
				console.info("[SENTRY] ChunkLoadError event suppressed");
				return null;
			}

			// Do not send known error from BabyList script.
			if (
				event.exception?.values?.some((value) =>
					value.value?.includes("getProductDefaults"),
				)
			) {
				console.info("[SENTRY] Ignoring known error from BabyList script");
				return null;
			}

			if (
				ignoreErrors.some((errorValue) => event.message?.includes(errorValue))
			) {
				console.info("[SENTRY] Ignoring known error in error message");
				return null;
			}

			if (
				event.exception?.values?.some((value) =>
					ignoreErrors.find((errorValue) => value.value?.includes(errorValue)),
				)
			) {
				console.info("[SENTRY] Ignoring known error in exception values");
				return null;
			}

			return event;
		},
		denyUrls: [
			/\.bazaarvoice\.com/,
			/^https:\/\/photorankstatics-a\.akamaihd\.net/,
			/^https:\/\/analytics\.tiktok\.com/,
			/^https:\/\/ipv6check\.ksearchnet\.com\//,
		],
	});

	Sentry.setTags({
		storyblok_environment: process.env.GATSBY_STORYBLOK_STATUS || "production",
		branch: process.env.GATSBY_BRANCH,
	});
}
