import * as Cookies from "es-cookie";
import { isBrowser } from "../browser-utils";
import { hasCookieConsent, type CookieConsent } from "./cookie-consent-service";

type SetCookieProps = {
	name: string;
	value: string;
	requireConsent: CookieConsent;
	attributes?: Cookies.CookieAttributes;
};

declare global {
	interface Window {
		CookieScript?: {
			instance: {
				currentState: () => {
					categories: string[];
				};
				show: () => void;
			};
		};
	}
}

const consentCookies: Record<string, CookieConsent> = {};
let listnersAdded = false;

const addCookieEventListener = () => {
	if (listnersAdded) return;
	if (!isBrowser()) return;

	window.addEventListener("CookieScriptLoaded", () => {
		console.log("CookieScriptLoaded event listener");
		for (const [name, requireConsent] of Object.entries(consentCookies)) {
			if (
				hasCookie(name) &&
				window.CookieScript?.instance &&
				!window.CookieScript.instance
					.currentState()
					.categories.includes(requireConsent)
			) {
				console.log("Removing cookie on consent change", name);
				Cookies.remove(name);
			}
		}
	});

	listnersAdded = true;
};

export const setCookie = (cookie: SetCookieProps) => {
	const { name, value, requireConsent, attributes } = cookie;

	if (hasCookieConsent(requireConsent)) {
		console.log("Setting cookie", name);
		Cookies.set(name, value, attributes);
	} else {
		window.addEventListener(`CookieScriptCategory-${requireConsent}`, () => {
			console.log("Setting cookie on consent change", name);
			Cookies.set(name, cookie.value, cookie.attributes);
		});
	}
};

export const handleCookieConsent = (
	cookieName: string,
	consentCategory: Exclude<CookieConsent, "strict">,
) => {
	consentCookies[cookieName] = consentCategory;
	addCookieEventListener();
};

/**
 *
 * @param name The name of the cookie to fetch
 * @returns JSON object with the key and value of the cookie
 */
export const getCookie = (name: string): string | undefined => {
	if (typeof document === "undefined") return undefined;
	return Cookies.get(name);
};

export const hasCookie = (name: string): boolean =>
	getCookie(name) !== undefined;

export const removeCookie = (name: string): void => Cookies.remove(name);

export const openCookieSettings = () => {
	if (window.CookieScript) window.CookieScript.instance.show();
};
