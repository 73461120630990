exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-bundle-page-bundle-page-container-tsx": () => import("./../../../src/templates/bundle-page/bundle-page.container.tsx" /* webpackChunkName: "component---src-templates-bundle-page-bundle-page-container-tsx" */),
  "component---src-templates-cart-page-cart-page-container-tsx": () => import("./../../../src/templates/cart-page/cart-page.container.tsx" /* webpackChunkName: "component---src-templates-cart-page-cart-page-container-tsx" */),
  "component---src-templates-category-page-category-page-container-tsx": () => import("./../../../src/templates/category-page/category-page.container.tsx" /* webpackChunkName: "component---src-templates-category-page-category-page-container-tsx" */),
  "component---src-templates-checkout-page-checkout-page-container-tsx": () => import("./../../../src/templates/checkout-page/checkout-page.container.tsx" /* webpackChunkName: "component---src-templates-checkout-page-checkout-page-container-tsx" */),
  "component---src-templates-order-confirmation-page-order-confirmation-page-container-tsx": () => import("./../../../src/templates/order-confirmation-page/order-confirmation-page.container.tsx" /* webpackChunkName: "component---src-templates-order-confirmation-page-order-confirmation-page-container-tsx" */),
  "component---src-templates-page-page-container-tsx": () => import("./../../../src/templates/page/page.container.tsx" /* webpackChunkName: "component---src-templates-page-page-container-tsx" */),
  "component---src-templates-product-page-product-page-container-tsx": () => import("./../../../src/templates/product-page/product-page.container.tsx" /* webpackChunkName: "component---src-templates-product-page-product-page-container-tsx" */),
  "component---src-templates-search-page-search-page-container-tsx": () => import("./../../../src/templates/search-page/search-page.container.tsx" /* webpackChunkName: "component---src-templates-search-page-search-page-container-tsx" */),
  "component---src-templates-stores-page-stores-page-container-tsx": () => import("./../../../src/templates/stores-page/stores-page.container.tsx" /* webpackChunkName: "component---src-templates-stores-page-stores-page-container-tsx" */)
}

