import { KlevuConfig } from "@klevu/core";
import type { GatsbyBrowser } from "gatsby";
import ContextWrapper from "./src/containers/ContextWrapper";
import { CoreErrorBoundary } from "./src/containers/core-error-boundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

let isNodePrototypePatched = false;
let isKoreanFontLoaded = false;
let isKlevuInitialized = false;

const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (props) => {
	if (!isNodePrototypePatched && typeof Node === "function") {
		// eslint-disable-next-line @typescript-eslint/unbound-method
		const originalRemoveChild = Node.prototype.removeChild;
		Node.prototype.removeChild = function <T extends Node>(child: T): T {
			if (child.parentNode !== this) {
				console.error(
					"Cannot remove a child from a different parent",
					child,
					this,
				);

				return child;
			}
			return Reflect.apply(originalRemoveChild, this, [child]);
		};

		// eslint-disable-next-line @typescript-eslint/unbound-method
		const originalInsertBefore = Node.prototype.insertBefore;
		Node.prototype.insertBefore = function <T extends Node>(
			newNode: T,
			referenceNode: Node | null,
		): T {
			if (referenceNode && referenceNode.parentNode !== this) {
				console.error(
					"Cannot insert before a reference node from a different parent",
					referenceNode,
					this,
				);

				return newNode;
			}
			return Reflect.apply(originalInsertBefore, this, [
				newNode,
				referenceNode,
			]);
		};

		isNodePrototypePatched = true;
	}

	if (!isKoreanFontLoaded && process.env.GATSBY_LANGUAGE === "ko-KR") {
		// eslint-disable-next-line @typescript-eslint/no-require-imports
		require("./src/styles/locales/ko.css");
		isKoreanFontLoaded = true;
	}

	if (
		!isKlevuInitialized &&
		process.env.GATSBY_KLEVU_API_KEY &&
		process.env.GATSBY_KLEVU_API_URL
	) {
		KlevuConfig.init({
			url: `${process.env.GATSBY_KLEVU_API_URL}/cs/v2/search`,
			apiKey: process.env.GATSBY_KLEVU_API_KEY,
			enableKlaviyoConnector: true, // Enable Klaviyo integration for segmentation in Recommendations
		});
		isKlevuInitialized = true;
	}

	return (
		<CoreErrorBoundary>
			<QueryClientProvider client={queryClient}>
				<ContextWrapper>{props.element}</ContextWrapper>
			</QueryClientProvider>
		</CoreErrorBoundary>
	);
};
