import type { BabyBjornCart } from "@babybjorn/utils/commercetools/custom-types/babybjorn-order";
import type { Payment } from "@commercetools/platform-sdk";
import { graphql, useStaticQuery } from "gatsby";
import {
	createContext,
	useContext,
	useReducer,
	type FC,
	type PropsWithChildren,
	type Reducer,
} from "react";
import { checkoutReducer, type CheckoutContextAction } from "./reducer";

export type CheckoutContextState = {
	cart?: BabyBjornCart;
	cartLoading: boolean;
	adyenMerchantAccount?: string;
	adyenLoading: boolean;
	paymentInProgress: boolean;
	adyenExpressPaymentOngoing: boolean;
	activeAdyenPayment?: Payment;
	showAddressForm: boolean;
	paymentError?: string;
	paymentIsDone?: boolean;
};

type Dispatch<CheckoutContextAction> = (action: CheckoutContextAction) => void;

const StateContext = createContext<CheckoutContextState | undefined>(undefined);

export const CheckoutDispatchContext = createContext<
	Dispatch<CheckoutContextAction> | undefined
>(undefined);

const query = graphql`
	query CheckoutContext {
		commerceToolsStore {
			adyenMerchantAccount
		}
	}
`;

const initialState = {
	adyenExpressPaymentOngoing: false,
	adyenLoading: false,
	showAddressForm: true,
	cartLoading: true,
	paymentInProgress: false,
	expressPaymentInProgress: false,
	showExpressPayment: false,
	expressPaymentIsDone: false,
};

export const CheckoutContextProvider: FC<PropsWithChildren> = ({
	children,
}) => {
	const data = useStaticQuery<Queries.CheckoutContextQuery>(query);
	const adyenMerchantAccount =
		data.commerceToolsStore?.adyenMerchantAccount ?? undefined;
	const [state, dispatch] = useReducer<
		Reducer<CheckoutContextState, CheckoutContextAction>
	>(checkoutReducer, { ...initialState, adyenMerchantAccount });

	return (
		<StateContext.Provider value={state}>
			<CheckoutDispatchContext.Provider value={dispatch}>
				{children}
			</CheckoutDispatchContext.Provider>
		</StateContext.Provider>
	);
};

export const useCheckoutState = (): CheckoutContextState => {
	const context = useContext(StateContext);

	if (!context) {
		throw new Error(
			`useCheckoutState must be used within a CheckoutContextProvider`,
		);
	}
	return context;
};
